import React from 'react';
import './ButtonStyles.css';

const Button = ({ children, className = '', onClick }) => {
  return (
    <button
      className={`vmp-button ${className}`}
      onClick={(e) => typeof onClick !== 'undefined' && onClick(e)}>
      {children}
    </button>
  );
};

export default Button;
