import LogRocket from 'logrocket';
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';

const LogRocketLogger = () => {
  useEffect(() => {
    const logRocketUrl = process.env.REACT_APP_LOGROCKET_URL;
    if (logRocketUrl && !process.env.REACT_APP_NODE_ENV === 'development')
      LogRocket.init(logRocketUrl);
  }, []);
  return null;
};

export default LogRocketLogger;
