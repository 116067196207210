import React from 'react';
import './SocialShare.css';

class SocialShare extends React.Component {
  state = {};

  shareClick = () => {
    if (this.props.onShare != null) {
      var shareRequest = {
        shareType: this.props.shareType,
        imageCode: this.props.imageCode,
        imageUrl: this.props.imageUrl,
      };
      this.props.onShare(shareRequest);
    }
  };

  render() {
    const imageStyle = {
      width: this.props.iconSize,
      cursor: 'pointer',
    };

    return (
      <div className='social-share'>
        <img
          src={'/assets/images/social-media' + this.props.iconUrl}
          alt='social icon'
          style={imageStyle}
          draggable={false}
          onClick={(e) => {
            e.preventDefault();
            this.shareClick();
          }}
        />
        {this.props.shareType === 'whatsapp' ? (
          <a
            id={'hiddenwalink_' + this.props.imageCode}
            className='whatsapp-hide'
            href={`whatsapp://send?text=${window.location.origin}/${this.props.galleryCode}/${this.props.imageCode}_w`}
            data-action='share/whatsapp/share'
          >
            wa
          </a>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default SocialShare;
