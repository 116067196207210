import React from 'react';
import './SalesHeader.css';
import basketIcon from '../../images/basket_white.png';
import DownloadAllButton from '../../components/download-all-button/DownloadAllButton';

const SalesHeader = ({
  downloadableImages,
  allowSales,
  allowAllSales,
  currency,
  saleSettings,
  onChangeCurrency,
  allowChangeCurrency,
  onCheckout,
  basket,
}) => {
  const changeCurrency = () => {
    onChangeCurrency();
  };
  const checkout = () => {
    onCheckout(false);
  };
  const checkoutAll = () => {
    onCheckout(true);
  };

  const headerStyle = {
    backgroundColor: saleSettings?.salesControlsColor || 'inherit',
  };

  return (
    <div className="sales-header" style={headerStyle}>
      <div className="sh-workspace">
        {allowSales ? (
          <>
            <div className="shw-buy-currency">
              {allowAllSales ? (
                <div className="shw-buy-all" onClick={checkoutAll}>
                  <div className="shw-buy-all-title">Buy All:</div>
                  <div className="shw-buy-all-price">
                    {currency.symbol} {currency.imageSetPrice.toFixed(2)}
                  </div>
                </div>
              ) : (
                <div className="shw-buy-all-placeholder"></div>
              )}
              <div className="shw-change-currency">
                <div className="currency-details">Currency:</div>
                <div className="currency-image">
                  <img
                    className="currency-img"
                    src={`/assets/images/${currency.icon}`}
                    alt="currency"
                  />
                </div>
                <div className="currency-code">{currency.code}</div>
                {allowChangeCurrency ? (
                  <div className="currency-change" onClick={changeCurrency}>
                    change
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="shw-basket-container">
              <div className="shw-basket" onClick={checkout}>
                <div className="shw-basket-details">
                  <div className="shw-basket-image">
                    <img
                      className="shw-basket-img"
                      src={basketIcon}
                      alt="basket"
                    />
                  </div>
                  <div className="shw-basket-total">
                    {basket?.images?.length}
                  </div>
                </div>
                <div className="shw-basket-title">Checkout</div>
              </div>
            </div>
          </>
        ) : (
          /* Only show the download all button when all images have been purchased and sales mode is turned off */
          <DownloadAllButton downloadableImages={downloadableImages} />
        )}
      </div>
    </div>
  );
};

export default SalesHeader;
