import React from 'react';
import PaygateService from './paygateService';
import { Link } from 'react-router-dom';
import SnaptAPI from './snaptAPI';
import LogRocket from 'logrocket';

class PaygateRedirect extends React.Component {
  query = window.location.search;

  componentDidMount() {
    const gateway = new URLSearchParams(this.query).get('gateway');

    switch (gateway) {
      case 'paygate':
        this.verify();
        break;

      case 'stripe':
        this.stripe();
        break;

      default:
        window.location.href = '/';
        break;
    }
  }

  state = {
    message: '',
    showBack: false,
  };

  redirect = (e) => {
    let code = new URLSearchParams(this.query).get('code');

    if (!code) {
      const localStore = localStorage.getItem('GALLERY_CODE');
      if (!localStore) {
        code = '';
      } else {
        code = localStore;
      }
    }

    window.location.href = `/${code}`;
  };

  stripe = async () => {
    const checkoutId = new URLSearchParams(this.query).get('checkoutid');
    if (!checkoutId) {
      this.setState({
        message: 'There was an issue with the payment, please contact support.',
        showBack: true,
      });
      LogRocket.captureException('No checkout Id found in url', {
        tags: ['checkout', 'stripe'],
      });
      return;
    }

    const verifySaleData = {
      paymentProvider: 'Stripe',
      checkoutId: checkoutId,
      cartId: '',
      orderId: '',
      payerId: '',
      paymentId: '',
      paymentToken: 'REDIRECT',
      paid: true,
      cancelled: false,
    };

    await SnaptAPI.salesVerify(verifySaleData, this.redirect);
    localStorage.setItem('PAYMENT_STATUS', 'success');
  };

  verify = async () => {
    const paymentId = localStorage.getItem('PAY_REQUEST_ID');
    const secret = localStorage.getItem('CLIENT_SECRET');
    const id = localStorage.getItem('CLIENT_ID');

    try {
      const qrCode = new URLSearchParams(this.query).get('code');
      const result = await PaygateService.queryPayment(
        id,
        secret,
        '',
        paymentId,
        qrCode,
      );
      const split = result.split('&');
      const checkoutId = new URLSearchParams(this.query).get('checkoutid');

      const transactionStatus = split.find((s) =>
        s.includes('TRANSACTION_STATUS'),
      );
      if (!transactionStatus) {
        this.setState({
          message:
            'There was an error processing the payment, please try again.',
          showBack: true,
        });
        return;
      }

      const verifySaleData = {
        paymentProvider: 'Paygate',
        checkoutId: '',
        cartId: '',
        orderId: '',
        payerId: '',
        paymentId: paymentId,
        paymentToken: 'REDIRECT',
        paid: false,
        cancelled: true,
      };

      localStorage.removeItem('PAY_REQUEST_ID');
      localStorage.removeItem('CLIENT_SECRET');
      localStorage.removeItem('CLIENT_ID');

      switch (transactionStatus.split('=')[1]) {
        case '0':
          this.setState({
            message:
              'The payment was not completed please, please try again later!',
            showBack: true,
          });
          break;

        case '1':
          this.setState({
            message:
              'The payment was made successfully, you will be redirected shortly!',
            showBack: false,
          });

          verifySaleData.paid = true;
          verifySaleData.cancelled = false;
          localStorage.setItem('PAYMENT_STATUS', 'success');
          await SnaptAPI.salesVerify(verifySaleData, this.redirect);
          break;

        case '2':
          this.setState({
            message: 'The payment was declined, please try again later!',
            showBack: true,
          });
          let message = split.find((s) => s.includes('RESULT_DESC'));
          if (message) {
            message = message.split('=')[1].replaceAll('+', ' ');
          }
          const declinedDto = {
            checkoutId: checkoutId ?? '',
            abortReason: 2,
            message: message ?? 'Payment Declined by PayGate',
          };

          await SnaptAPI.salesAbort(declinedDto);
          break;

        case '3':
          this.setState({
            message: 'The payment was cancelled, please try again later!',
            showBack: true,
          });
          let cMessage = split.find((s) => s.includes('RESULT_DESC'));
          if (cMessage) {
            cMessage = cMessage.split('=')[1].replaceAll('+', ' ');
          }
          const cancDto = {
            checkoutId: checkoutId ?? '',
            abortReason: 1,
            message: cMessage ?? 'Payment Cancelled by PayGate',
          };

          await SnaptAPI.salesAbort(cancDto);
          break;

        case '4':
          this.setState({
            message:
              'The payment was cancelled by you, please try again later!',
            showBack: true,
          });
          const cancelledDto = {
            checkoutId: checkoutId ?? '',
            abortReason: 0,
            message: 'Payment Cancelled by User',
          };

          await SnaptAPI.salesAbort(cancelledDto);
          break;

        default:
          this.setState({
            message:
              'There was an issue with the payment, please try again later!',
            showBack: true,
          });

          const failedMessage = 'There was an issue with the payment';
          const failedDto = {
            checkoutId: checkoutId ?? '',
            abortReason: 2,
            message: failedMessage,
          };

          await SnaptAPI.salesAbort(failedDto);
          break;
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <>
        <h3>Verifying Your Payment...</h3>

        <p>{this.state.message}</p>

        {this.state.showBack && <Link to="/">Go back</Link>}
      </>
    );
  }
}

export default PaygateRedirect;
