import React from 'react';
import './PhotoBlock.css';


class PhotoBlock extends React.Component {

  state = {
    
  }


  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

  render() {

    var rgbValues = this.hexToRgb(this.props.websiteSettings.photoFrameColor);
    var backgroundColor = "rgb(" + rgbValues.r  + "," + rgbValues.g  + "," +  rgbValues.b  + "," + this.props.websiteSettings.photoFrameOpacity + ")";


    const frameContainerStyle = {
      paddingLeft:          this.props.websiteSettings.photoFrameSidePadding,
      paddingRight:         this.props.websiteSettings.photoFrameSidePadding,
    };

    const frameStyle = {
      backgroundColor:      backgroundColor,
      padding:              this.props.websiteSettings.photoFrameWidth,
      borderTopRightRadius: this.props.websiteSettings.photoFrameRoundedBorders ? "10px" : "unset",
      borderTopLeftRadius:  this.props.websiteSettings.photoFrameRoundedBorders ? "10px" : "unset",
    };

    const imageStyle = {
      width:"100%",
      opacity:1
    }


    return (
        <div className="photo-block" style={frameContainerStyle}> 
          <div className="photo-frame" style={frameStyle}>
              {this.props.image.imageURL !== "" ?
                <img src={this.props.image.imageURL} alt='snapt url'
                    style={imageStyle}>
                </img>
                :
                <img src={this.props.image.watermarkedImageURL} alt='watermarked'
                  style={imageStyle}>
                </img>      
              }
          </div>
        </div>
    );
  }


    

}

export default PhotoBlock;