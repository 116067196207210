import LogRocket from 'logrocket';

class SnaptAPI {
  // Set API Root
  static apiRoot() {
    //return "http://localhost:50555/";
    return process.env.REACT_APP_API_URL ?? 'https://localhost:5000/api/';
  }
  static fileServerRoot() {
    // return "http://localhost:777/";
    return 'https://files.snaptmedia.com/';
  }

  // Headers
  static authenticatedHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ3MTI5MTI5ODMsImlzcyI6ImFkbWluLnNuYXB0LnBpY3MiLCJhdWQiOiJzbmFwdHNlcnZpY2VzIn0.QJ33mm9q-zKk_EZFK9xxip_eWjRwFY0W_vg6bJqTdmU',
    };
  }

  // Gallery Search
  static async gallerySearch(searchCode, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'gallery/' + searchCode, {
        method: 'GET',
        headers: SnaptAPI.authenticatedHeaders(),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              return response.json();
            case 400:
            case 404:
              return response.json();
            default:
              return '';
          }
        })
        .then((responseJson) => {
          if (responseJson === '') {
            onFail('Failed to search gallery');
            return;
          }

          if (responseJson.error) {
            switch (responseJson.error) {
              default:
              case 'Invalid Search Code':
                onFail('BadCode');
                return;

              case 'No Images Found.':
                onFail('NoImages');
                return;
            }
          }

          onSucess(responseJson);
          return;
        })
        .catch((error) => {
          console.log(error);
          LogRocket.captureException(error, {
            extra: ['passing server json'],
            tags: ['gallery-fetch'],
          });
          onFail('The system encountered an error whilst searching');
          return;
        });
    } catch (error) {
      console.log(error);
      LogRocket.captureException(error, {
        tags: ['gallery-fetch'],
      });
      onFail('The system encountered an exception whilst searching');
      return;
    }
  }
  static async galleryCodeValidate(searchCode, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'gallery/validate/' + searchCode, {
        method: 'GET',
        headers: SnaptAPI.authenticatedHeaders(),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              return onSucess();
            case 400:
            case 404:
              return response.json();
            default:
              return onFail();
          }
        })
        .then((jsonResponse) => {
          if (jsonResponse !== '') {
            if (!jsonResponse.isSuccess) {
              switch (jsonResponse.error) {
                default:
                case 'Invalid Search Code':
                  onFail('BadCode');
                  break;

                case 'No Images Found.':
                  onFail('NoImages');
                  break;
              }
            }
          }
        })
        .catch((error) => {
          LogRocket.captureException(error, {
            tags: ['gallery-fetch'],
          });
          onFail(
            'The system encountered an error whilst validating gallery code',
          );
          return;
        });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: ['gallery-fetch'],
      });
      onFail('The system encountered an error whilst validating gallery code');
      return;
    }
  }

  // Share Intention
  static async shareIntention(shareData, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'shareintention', {
        method: 'POST',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(shareData),
      })
        .then((response) => {
          switch (response.status) {
            case 400:
              return '';
            case 200:
            case 204:
              if (onSucess != null) return response.json();
              return;
            default:
              return '';
          }
        })
        .then((responseJson) => {
          if (responseJson !== '') {
            onSucess(responseJson);
            return;
          }
          onFail('Failed to search gallery.');
          return;
        })
        .catch((error) => {
          console.log(error);
          if (onFail != null) onFail('Share intention failed');
          return;
        });
    } catch (error) {
      console.log(error);
      if (onFail != null) onFail('Share intention failed exception');
      return;
    }
  }

  // Data collection
  static async dataCollection(collectionData, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'datacollections', {
        method: 'POST',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(collectionData),
      })
        .then((response) => {
          switch (response.status) {
            case 400:
              return '';
            case 200:
            case 204:
              if (onSucess != null) onSucess();
              return;
            default:
              return '';
          }
        })
        .catch((error) => {
          console.log(error);
          if (onFail != null) onFail('Data collection failed');
          return;
        });
    } catch (error) {
      console.log(error);
      if (onFail != null) onFail('Data collection failed exception');
      return;
    }
  }

  // Website Information
  static async getWebsiteInfo(
    templateId,
    galleryCode,
    infoType,
    onSucess,
    onFail,
  ) {
    try {
      await fetch(
        this.apiRoot() +
          'info/' +
          templateId +
          '/' +
          galleryCode +
          '/' +
          infoType,
        {
          method: 'GET',
          headers: SnaptAPI.authenticatedHeaders(),
        },
      )
        .then((response) => {
          switch (response.status) {
            case 400:
              return '';
            case 200:
            case 204:
              return response.json();
            default:
              return '';
          }
        })
        .then((responseJson) => {
          if (responseJson !== '') {
            onSucess(responseJson);
            return;
          }
          onFail('Failed to get info.');
          return;
        })
        .catch((error) => {
          console.log(error);
          onFail('The system encountered an error whilst getting information');
          return;
        });
    } catch (error) {
      console.log(error);
      onFail('The system encountered an exception whilst getting information');
      return;
    }
  }

  // Sales Checkout
  static async salesCheckout(checkoutData, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'sales/checkout', {
        method: 'POST',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(checkoutData),
      })
        .then((response) => {
          switch (response.status) {
            case 400:
              return '';
            case 200:
            case 204:
              if (onSucess != null) return response.json();
              return;
            default:
              return '';
          }
        })
        .then((responseJson) => {
          if (responseJson !== '') {
            onSucess(responseJson);
            return;
          }
          onFail('Failed to do checkout.');
          return;
        })
        .catch((error) => {
          console.log(error);
          if (onFail != null) onFail('Checkout failed');
          return;
        });
    } catch (error) {
      console.log(error);
      if (onFail != null) onFail('Checkout failed exception');
      return;
    }
  }
  static async salesVerify(saleVerificationData, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'sales/verify', {
        method: 'POST',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(saleVerificationData),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              if (onSucess != null) onSucess(response);
              return;
            case 500:
              onFail();
              return;
            default:
              return '';
          }
        })
        .catch((error) => {
          console.log(error);
          LogRocket.captureException(error, {
            tags: ['checkout', 'verify-sale'],
          });
          if (onFail != null) onFail('Sale verification failed');
          return;
        });
    } catch (error) {
      console.log(error);
      LogRocket.captureException(error, {
        tags: ['checkout', 'verify-sale'],
      });
      if (onFail != null) onFail('Sale verification failed');
      return;
    }
  }
  static async salesAbort(saleAbortData, onSucess, onFail) {
    LogRocket.captureException(saleAbortData, {
      tags: ['checkout', 'abort sale'],
    });
    try {
      await fetch(this.apiRoot() + 'sales/abort', {
        method: 'POST',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(saleAbortData),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              if (onSucess != null) onSucess();
              return;
            case 500:
              onFail();
              return;
            default:
              return '';
          }
        })
        .catch((error) => {
          console.log(error);
          LogRocket.captureException(error, {
            tags: ['checkout', 'abort sale'],
          });
          if (onFail != null) onFail('Sale abort failed');
          return;
        });
    } catch (error) {
      console.log(error);

      if (onFail != null) onFail('Sale abort failed');
      return;
    }
  }

  // Paytgate
  static async salesPaygateInitiate(paygateInitiateData, onSucess, onFail) {
    try {
      await fetch(this.apiRoot() + 'sales/paygate/initiate', {
        method: 'POST',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(paygateInitiateData),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              if (onSucess != null) return response.json();
              return;
            case 500:
              onFail();
              return;
            default:
              return '';
          }
        })
        .then((responseJson) => {
          if (responseJson !== '') {
            onSucess(responseJson);
            return;
          }
          onFail('Paygate sale initiation failed');
          return;
        })
        .catch((error) => {
          console.log(error);
          if (onFail != null) onFail('{Paygate sale initiation failed}');
          return;
        });
    } catch (error) {
      console.log(error);
      if (onFail != null) onFail('Paygate sale initiation failed');
      return;
    }
  }

  static async salesUpdateProviderId(checkoutData) {
    try {
      await fetch(this.apiRoot() + 'sales/updateid', {
        method: 'PUT',
        headers: SnaptAPI.authenticatedHeaders(),
        body: JSON.stringify(checkoutData),
      })
        .then((response) => {
          switch (response.status) {
            case 400:
              return '';
            case 200:
            case 204:
              return;
            default:
              return '';
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    } catch (error) {
      throw error;
    }
  }
}

export default SnaptAPI;
