import React from 'react';
import './PaygateButton.css';
import SnaptAPI from '../../services/snaptAPI';
import paygateButtonImg from '../../images/paygate-required.png';
import PaygateService from '../../services/paygateService';

class PaygateButton extends React.Component {
  state = {
    status: 'idle',
  };

  componentDidMount() {}

  onPayClick = async (e) => {
    try {
      e.preventDefault();
      this.setState({ status: 'initiating' });
      // SnaptAPI.salesPaygateInitiate(paygateInitiateData,this.paymentInitiateSuccess,this.paymentInitiateFailed);
      const result = await PaygateService.startPayment(
        this.props.cost,
        this.props.clientId,
        this.props.clientSecret,
        this.props.currency,
        this.props.checkoutId,
        this.props.galleryCode,
      );

      // 0 and 3
      const split = result.split('&');

      localStorage.setItem('PAY_REQUEST_ID', split[1].split('=')[1]);
      localStorage.setItem('CLIENT_ID', this.props.clientId);
      localStorage.setItem('CLIENT_SECRET', this.props.clientSecret);
      localStorage.setItem('GALLERY_CODE', this.props.galleryCode);

      await SnaptAPI.salesUpdateProviderId({
        checkoutId: this.props.checkoutId,
        paymentProviderSaleId: split[1].split('=')[1],
      });
      this.props.onSuccess();

      this.paymentInitiateSuccess({
        checksum: split[3].split('=')[1],
        paygatePayRequestId: split[1].split('=')[1],
      });
    } catch (error) {
      console.error(error);
      this.paymentInitiateFailed(error);
    }
  };
  paymentInitiateSuccess = (data) => {
    this.setState({ status: 'idle' });

    var f = document.createElement('form');
    f.action = `https://secure.paygate.co.za/payweb3/process.trans`;
    f.method = 'POST';

    var i = document.createElement('input');
    i.type = 'hidden';
    i.name = 'PAY_REQUEST_ID';
    i.value = data.paygatePayRequestId;
    f.appendChild(i);

    var ic = document.createElement('input');
    ic.type = 'hidden';
    ic.name = 'CHECKSUM';
    ic.value = data.checksum;
    f.appendChild(ic);

    document.body.appendChild(f);
    f.submit();
  };
  paymentInitiateFailed = (error) => {
    alert('Payment initiation failed. Please try again');
    this.setState({ status: 'idle' });
  };

  render() {
    return (
      <div className="paygate-button" onClick={(e) => this.onPayClick(e)}>
        <div className="paygate-button-image">
          <img
            src={paygateButtonImg}
            className="paygate-button-image-img"
            alt="paygate_button"></img>
        </div>
      </div>
    );
  }
}

export default PaygateButton;
