import React from 'react';
import './SocialBlock.css';
import SocialShare from '../social-share/SocialShare';

class SocialBlock extends React.Component {
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  render() {
    var rgbValues = this.hexToRgb(this.props.websiteSettings.photoFrameColor);
    var backgroundColor =
      'rgb(' +
      rgbValues.r +
      ',' +
      rgbValues.g +
      ',' +
      rgbValues.b +
      ',' +
      this.props.websiteSettings.photoFrameOpacity +
      ')';
    var downloadIconUrl =
      this.props.websiteSettings.socialCustomDownloadIconUrl;
    var facebookIconUrl =
      this.props.websiteSettings.socialCustomFacebookIconUrl;
    var twitterIconUrl = this.props.websiteSettings.socialCustomTwitterIconUrl;
    var whatsappIconUrl =
      this.props.websiteSettings.socialCustomWhatsappIconUrl;
    if (this.props.websiteSettings.socialIconsSet.toLowerCase() !== 'custom') {
      downloadIconUrl = `/download-${this.props.websiteSettings.socialIconsSet.toLowerCase()}.png`;
      facebookIconUrl = `/facebook-${this.props.websiteSettings.socialIconsSet.toLowerCase()}.png`;
      twitterIconUrl = `/twitter-${this.props.websiteSettings.socialIconsSet.toLowerCase()}.png`;
      whatsappIconUrl = `/whatsapp-${this.props.websiteSettings.socialIconsSet.toLowerCase()}.png`;
    }

    const socialBlockStyle = {
      paddingLeft: this.props.websiteSettings.photoFrameSidePadding,
      paddingRight: this.props.websiteSettings.photoFrameSidePadding,
    };
    const socialContainerStyle = {
      backgroundColor: backgroundColor,
      padding: this.props.websiteSettings.photoFrameWidth,
      paddingTop: 0,
      borderBottomRightRadius: this.props.websiteSettings
        .photoFrameRoundedBorders
        ? '10px'
        : 'unset',
      borderBottomLeftRadius: this.props.websiteSettings
        .photoFrameRoundedBorders
        ? '10px'
        : 'unset',
    };

    return (
      <div className="social-block" style={socialBlockStyle}>
        <div className="social-container" style={socialContainerStyle}>
          {this.props.websiteSettings.socialAllowDownload ? (
            <div className="social-platform">
              <SocialShare
                shareType="download"
                onShare={(e) => {
                  this.props.onShare(e);
                }}
                iconUrl={downloadIconUrl}
                iconSize={this.props.websiteSettings.socialIconsSize}
                imageCode={this.props.image.imageCode}
                imageUrl={this.props.image.imageURL}
                galleryCode={this.props.galleryCode}></SocialShare>
            </div>
          ) : (
            ''
          )}

          {this.props.websiteSettings.socialAllowFacbookShare ? (
            <div className="social-platform">
              <SocialShare
                shareType="facebook"
                onShare={(e) => {
                  this.props.onShare(e);
                }}
                iconUrl={facebookIconUrl}
                iconSize={this.props.websiteSettings.socialIconsSize}
                imageCode={this.props.image.imageCode}
                imageUrl={this.props.image.imageURL}
                galleryCode={this.props.galleryCode}></SocialShare>
            </div>
          ) : (
            ''
          )}

          {this.props.websiteSettings.socialAllowTwitterShare ? (
            <div className="social-platform">
              <SocialShare
                shareType="twitter"
                onShare={(e) => {
                  this.props.onShare(e);
                }}
                iconUrl={twitterIconUrl}
                iconSize={this.props.websiteSettings.socialIconsSize}
                imageCode={this.props.image.imageCode}
                imageUrl={this.props.image.imageURL}
                galleryCode={this.props.galleryCode}></SocialShare>
            </div>
          ) : (
            ''
          )}

          {this.props.websiteSettings.socialAllowWhatsappShare ? (
            <div className="social-platform">
              <SocialShare
                shareType="whatsapp"
                onShare={(e) => {
                  this.props.onShare(e);
                }}
                iconUrl={whatsappIconUrl}
                iconSize={this.props.websiteSettings.socialIconsSize}
                imageCode={this.props.image.imageCode}
                imageUrl={this.props.image.imageURL}
                galleryCode={this.props.galleryCode}></SocialShare>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default SocialBlock;
