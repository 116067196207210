import React, { Component } from 'react';
import './DataCollectionModal.css';
import ReactModal from 'react-modal';
import DataCollectionRenderer from '../../renderers/data-collection-renderer/DataCollectionRenderer';
import ModalInfoDisplayRenderer from '../../renderers/modal-info-display-renderer/ModalInfoDisplayRenderer';

ReactModal.setAppElement('#root');

const customStyles = {
    content : {      
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      transform             : 'translate(-50%, -50%)',
      width	                : 'auto',
      height	              : 'auto',
      outline               : 'none',
    }
  };

class DataCollectionModal extends Component {

    state = {
      showTerms:false
    };

    showTerms = () => {
      this.setState({showTerms:true});
    }
    termsPrivacyClose = () => {
      this.setState({showTerms:false});
    }

    componentDidMount(){
  
    }
  
    render() {
          return(   
            <div>  

              <ReactModal 
                  isOpen={this.props.isOpen && !this.state.showTerms}
                  contentLabel="Confirmation"
                  style={customStyles}
                  className="data-collection-modal"
                  overlayClassName="data-collection-modal-overlay">
                      <div className="">
                          <DataCollectionRenderer 
                              collectionSettings={this.props.dataCollectionSettings}
                              onSubmit={(e) => {this.props.onSubmit(e)}} 
                              onDecline={(e) => {this.props.onDecline(e)}} 
                              onCancel={(e) => {this.props.onCancel(e)}} 
                              galleryCode={this.props.galleryCode}
                              onShowTerms={(e) => {this.showTerms(e)}}
                              >
                          </DataCollectionRenderer>
                      </div>
              </ReactModal>

              <ReactModal 
                  isOpen={this.state.showTerms}
                  contentLabel="Terms And Privacy"
                  style={customStyles}
                  className="data-collection-modal"
                  overlayClassName="data-collection-modal-overlay">
                      <div className="">
                          <ModalInfoDisplayRenderer 
                              collectionId={this.props.dataCollectionSettings.collectionCookieId}
                              galleryCode={this.props.galleryCode}
                              infoType={"template-terms-privacy"}
                              onClose={this.termsPrivacyClose} >
                          </ModalInfoDisplayRenderer>
                      </div>
              </ReactModal>    

            </div>

          );
      }
  }
  
  export default DataCollectionModal;