import ReactGA from 'react-ga';
import './Gallery.css';
import SnaptAPI from '../../services/snaptAPI';
import GalleryRenderer from '../../renderers/gallery-renderer/GalleryRenderer';
import NoImageError from '../../components/no-image-error/NoImageError';

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const Gallery = () => {
  const [galleryCode, setGalleryCode] = useState('');
  const [imageCode, setImageCode] = useState('');
  const [websiteSettings, setWebsiteSettings] = useState(null);
  const [saleSettings, setSaleSettings] = useState(null);
  const [dataCollectionSettings, setDataCollectionSettings] = useState(null);
  const [headerImageBlock, setHeaderImageBlock] = useState(null);
  const [footerImageBlock, setFooterImageBlock] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState(null);
  const [status, setStatus] = useState('');
  const params = useParams();

  useEffect(() => {
    setGalleryCode(params.code);
    setImageCode(params.imageCode);
  }, [params.code, params.imageCode]);

  const retrieveGallery = useCallback(() => {
    setStatus('retrieving');
    SnaptAPI.gallerySearch(
      params.code,
      retrieveGallerySuccess,
      retrieveGalleryFailed,
    );
  }, [params.code]);

  useEffect(() => {
    ReactGA.initialize('UA-127917789-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    retrieveGallery();
  }, [retrieveGallery]);

  const retrieveGallerySuccess = (galleryData) => {
    if (galleryData.websiteSettings != null) {
      let headerImageBlock = null;
      for (let i = 0; i < galleryData.websiteSettings.imageBlocks.length; i++) {
        if (galleryData.websiteSettings.imageBlocks[i].placement === 0) {
          headerImageBlock = galleryData.websiteSettings.imageBlocks[i];
          break;
        }
      }
      setHeaderImageBlock(headerImageBlock);
      let footerImageBlock = null;
      for (let f = 0; f < galleryData.websiteSettings.imageBlocks.length; f++) {
        if (galleryData.websiteSettings.imageBlocks[f].placement === 1) {
          footerImageBlock = galleryData.websiteSettings.imageBlocks[f];
          break;
        }
      }
      setFooterImageBlock(footerImageBlock);
    }
    setWebsiteSettings(galleryData.websiteSettings);
    setSaleSettings(galleryData.saleSettings);
    setDataCollectionSettings(galleryData.dataCollectionSettings);
    setGalleryImages(galleryData.images);
    setAvailableCurrencies(
      galleryData.templateCurrencies != null
        ? galleryData.templateCurrencies.templateCurrencies
        : null,
    );
    setStatus('viewing');
  };

  const retrieveGalleryFailed = (error) => {
    if (error === 'BadCode') {
      setStatus('notfound');
    }
    if (error === 'NoImages') {
      setStatus('noImages');
    }
  };

  return status === 'viewing' ? (
    <div className="gallery-container">
      <GalleryRenderer
        websiteSettings={websiteSettings}
        dataCollectionSettings={dataCollectionSettings}
        saleSettings={saleSettings}
        headerImageBlock={headerImageBlock}
        footerImageBlock={footerImageBlock}
        imageList={galleryImages}
        availableCurrencies={availableCurrencies}
        galleryCode={galleryCode}
        imageCode={imageCode}
      />
    </div>
  ) : status === 'notfound' ? (
    <div className="gallery-not-found">
      <div>
        <img
          className="not-found-img"
          src="/assets/images/hsl_logo.png"
          alt="hsl logo"
        />

        <div className="not-found-title">oops!</div>
        <h3 className="not-found-subtitle">
          We can't seem to find the page you are looking for!
        </h3>

        <div className="not-found-back" onClick={() => this.goBack()}>
          Go back
        </div>
      </div>

      {/* <img className="not-found-img" src={notFoundImage} alt="Not Found" /> */}
    </div>
  ) : status === 'noImages' ? (
    <NoImageError code={galleryCode} />
  ) : (
    ''
  );
};

export default Gallery;
