/*global FB*/
import React from 'react';
import './GalleryRenderer.css';
import SalesHeader from '../sales-header/SalesHeader';
import ImageBlock from '../image-block/ImageBlock';
import PhotoBlock from '../photo-block/PhotoBlock';
import SocialBlock from '../social-block/SocialBlock';
import ImageSaleBlock from '../image-sale-block/ImageSaleBlock';
import DataCollectionModal from '../../modals/data-collection/DataCollectionModal';
import SalesCheckoutModal from '../../modals/sales-checkout/SalesCheckoutModal';
import SalesChangeCurrencyModal from '../../modals/sales-change-currency/SalesChangeCurrencyModal';
import SalesMessageModal from '../../modals/sales-message-modal/SalesMessageModal';
import SnaptAPI from '../../services/snaptAPI';
import Cookies from 'universal-cookie';
import PaygateFooter from '../../components/paygate-footer/PaygateFooter';
import SafariMessageModal from '../../modals/safari-message-modal/SafariMessageModal';
import { UAParser } from 'ua-parser-js';
import ErrorBanner from '../../components/error-banner/ErrorBanner';
import { toast } from 'react-toastify';
import { downloadImage } from '../../utils/ImageUtils';

class GalleryRenderer extends React.Component {
  state = {
    currentShareRequest: null,
    gallerySource: '',
    collectData: false,
    collectDataImageCode: '',
    allowSales: false,
    allowAllSales: false,
    basketGalleryCode: '',
    basket: null,
    currency: null,
    checkingOut: false,
    checkoutId: null,
    checkoutAll: null,
    changingCurrency: false,
    allowPayPal: this.props.saleSettings
      ? this.props.saleSettings.usePaymentProviderPayPal
      : false,
    allowPaygate:
      this.props.saleSettings &&
      this.props.saleSettings.paymentGateway === 'paygate',
    clientId: '',
    clientSecret: '',
    templateId: this.props.saleSettings
      ? this.props.saleSettings.templateId
      : null,
    safariMessage: false,
    browserName: '',
    showErrorBanner: false,
    errorBannerMessage: '',
    galleryCode: this.props.galleryCode,
  };

  componentDidMount() {
    // Find browser to displplay appropriate message
    const parser = new UAParser();
    this.setState({
      browserName: parser.getBrowser().name ?? '',
    });

    // Set Gallery Source
    var sourceDelimPos = window.location.href.indexOf('_');
    if (sourceDelimPos > 0) {
      var gallerySource = window.location.href.substring(
        sourceDelimPos,
        sourceDelimPos + 2,
      );
      this.setState({ gallerySource: gallerySource });
    }

    // Setup For Photo Sales
    var allowSales = false;
    this.props.imageList.forEach((image) => {
      if (image.watermarkedImageURL !== '' && image.imageURL === '') {
        allowSales = true;
      }
    });
    if (allowSales) {
      var firstUnderscorePos = this.props.galleryCode.indexOf('_');
      if (firstUnderscorePos === -1)
        firstUnderscorePos = this.props.galleryCode.length;
      var basketGalleryCode = this.props.galleryCode.substring(
        0,
        firstUnderscorePos,
      );
      const cookies = new Cookies();
      var basket = cookies.get('vmp-basket-' + basketGalleryCode);
      if (basket === undefined) {
        basket = { buyAllCode: '', images: [], currencyId: -1 };
      }
      var currency = null;
      if (basket.currencyId === -1) {
        this.props.availableCurrencies.forEach((availableCurrency) => {
          if (availableCurrency.isDefault) {
            currency = availableCurrency;
            basket.currencyId = availableCurrency.currencyId;
          }
        });
      } else {
        this.props.availableCurrencies.forEach((availableCurrency) => {
          if (availableCurrency.currencyId === basket.currencyId) {
            currency = availableCurrency;
          }
        });
      }
      if (currency === null) {
        currency = this.props.availableCurrencies[0];
        basket.currencyId = currency.currencyId;
      }

      basket.images = basket.images.filter((basketImage) => {
        const image = this.props.imageList.find(
          (e) => e.imageCode === basketImage.imageCode,
        );
        if (image) image.addedToBasket = true;

        return image ? image.imageURL === '' : basketImage.imageURL === '';
      });

      var allowAllSales =
        currency.singleImagePrice * this.props.imageList.length >=
          currency.imageSetPrice && this.props.imageList.length > 1;
      cookies.set('vmp-basket-' + basketGalleryCode, basket, {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });
      this.setState({
        allowSales,
        allowAllSales,
        basketGalleryCode,
        basket,
        currency,
      });
    }
    // If Collecting Data on Page Load - set CollectionGalleryCode
    if (
      this.props.dataCollectionSettings !== null &&
      this.props.dataCollectionSettings.collectionPoint === 0
    ) {
      var url = window.location.pathname;
      var galleryCode = url.substr(url.lastIndexOf('/') + 1);
      this.setState({ collectDataImageCode: galleryCode });
    }
    // Collect Data If Collecting Now
    var collectingData = this.dataCollectionDoDataCollection(0); // on page show collection
    if (collectingData) {
      this.setState({ collectData: true });
      this.dataCollectionResetVisitCount();
    } else {
      if (
        this.props.dataCollectionSettings !== null &&
        this.props.dataCollectionSettings.collectionPoint === 0
      )
        this.dataCollectionIncrementVisitCount();
    }
    // FB Initiialse
    window.fbAsyncInit = function () {
      FB.init({
        appId: '476122014184826',
        // appId      : '297880807585518',
        //appId      : '371600800126110',
        // secret 35476f90b89062817c83c8217e7e2185
        xfbml: true,
        version: 'v2.8',
      });
      FB.AppEvents.logPageView();
    };
    // Load Facebook SDK
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  // DATA COLLECTION FUNCTIONS
  dataCollectionDoDataCollection = (collectionPoint) => {
    var collectingData = false;
    if (
      this.props.dataCollectionSettings != null &&
      this.props.dataCollectionSettings.collectionPoint === collectionPoint
    ) {
      const cookies = new Cookies();
      if (
        !cookies.get(
          'vmp-data-collected-' +
            this.props.dataCollectionSettings.collectionCookieId,
        )
      ) {
        var dataCollectionVisitCount = cookies.get(
          'vmp-datacollection-visit-count-' +
            this.props.dataCollectionSettings.collectionCookieId,
        );
        if (
          isNaN(dataCollectionVisitCount) ||
          dataCollectionVisitCount === undefined
        ) {
          collectingData = true;
        } else {
          if (
            dataCollectionVisitCount >=
              this.props.dataCollectionSettings
                .collectionFrequencyTillCollected ||
            this.props.collectionSettings?.collectionEnforcing > 0
          ) {
            collectingData = true;
          }
        }
      }
    }
    return collectingData;
  };
  dataCollectionIncrementVisitCount = () => {
    if (this.props.dataCollectionSettings === null) return;
    const cookies = new Cookies();
    var dataCollectionVisitCount = cookies.get(
      'vmp-datacollection-visit-count-' +
        this.props.dataCollectionSettings.collectionCookieId,
    );
    if (
      isNaN(dataCollectionVisitCount) ||
      dataCollectionVisitCount === undefined
    ) {
      dataCollectionVisitCount = 1;
    } else {
      dataCollectionVisitCount++;
    }
    cookies.set(
      'vmp-datacollection-visit-count-' +
        this.props.dataCollectionSettings.collectionCookieId,
      dataCollectionVisitCount,
      {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      },
    );
  };
  dataCollectionResetVisitCount = () => {
    if (this.props.dataCollectionSettings === null) return;
    const cookies = new Cookies();
    var dataCollectionVisitCount = 1;
    cookies.set(
      'vmp-datacollection-visit-count-' +
        this.props.dataCollectionSettings.collectionCookieId,
      dataCollectionVisitCount,
      {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      },
    );
  };
  dataCollectionSubmit = (collectedData) => {
    SnaptAPI.dataCollection(collectedData, null, null);
    this.setState({ collectData: false });
    const cookies = new Cookies();
    cookies.set(
      'vmp-data-collected-' +
        this.props.dataCollectionSettings.collectionCookieId,
      true,
      {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      },
    );
    this.postDataCollectionPromptShare();
  };
  dataCollectionDecline = (e) => {
    this.setState({ collectData: false });
    this.postDataCollectionPromptShare();
  };
  dataCollectionCancel = (e) => {
    this.setState({ collectData: false, currentShareRequest: null });
  };
  postDataCollectionPromptShare = () => {
    if (this.state.currentShareRequest !== null) {
      this.doSocialShare(this.state.currentShareRequest);
    }
  };

  // SOCIAL SHARING FUCNTIONS
  socialShare = (shareRequest) => {
    var collectingData = this.dataCollectionDoDataCollection(1);
    var shareIntentRequestData = {
      shareType: shareRequest.shareType,
      imageCode: shareRequest.imageCode,
      imageUrl: shareRequest.imageUrl,
      dataCollected: collectingData,
      gallerySource: this.state.gallerySource,
    };
    SnaptAPI.shareIntention(
      shareIntentRequestData,
      this.socialShareIntentionSuccess,
      this.socialShareIntentionFailed,
    );
    if (collectingData) {
      this.setState({
        currentShareRequest: shareRequest,
        collectDataImageCode: shareRequest.imageCode,
        collectData: true,
      });
    } else {
      //this.doSocialShare(shareRequest);
    }
  };
  socialShareIntentionSuccess = (shareRequest) => {
    var collectingData = this.dataCollectionDoDataCollection(1);
    if (collectingData) {
      this.dataCollectionResetVisitCount();
    } else {
      this.dataCollectionIncrementVisitCount();
      this.doSocialShare(shareRequest);
    }
  };
  socialShareIntentionFailed = (message) => {
    alert('Oops. Something went wrong. Please try again');
  };
  doSocialShare(shareRequest) {
    const cookies = new Cookies();
    const safariMessageCookie = cookies.get('vmp_safariMessage') ?? '';
    if (
      (this.state.browserName === 'Safari' ||
        this.state.browserName === 'Mobile Safari') &&
      safariMessageCookie !== 'hide'
    ) {
      this.setState({ safariMessage: true });
    }

    switch (shareRequest.shareType) {
      case 'download':
        downloadImage(shareRequest.imageUrl);
        break;
      case 'facebook':
        this.socialShareFaceBook(shareRequest);
        break;
      case 'twitter':
        this.socialShareTwitter(shareRequest);
        break;
      case 'whatsapp':
        this.socialShareWhatsApp(shareRequest);
        break;
      default:
        throw new Error('Invalud share type', shareRequest);
    }
  }

  socialShareFaceBook(shareRequest) {
    var shareLink = `${window.location.origin}/${this.state.galleryCode}/${shareRequest.imageCode}_f`;
    // shareLink = 'https://viewmypics.s3-eu-west-1.amazonaws.com/RTJL6/912f79f4-564f-4900-9dbe-96b9877ba02a.jpg';
    FB.ui(
      {
        method: 'share',
        display: 'popup',
        mobile_iframe: true,
        href: shareLink,
        quote: this.props.websiteSettings.socialShareMessage,
        hashtag: `#${this.props.websiteSettings.socialShareHashtags}`,
      },
      function (response) {},
    );
  }
  socialShareTwitter(shareRequest) {
    var shareLink = `${window.location.origin}/${this.state.galleryCode}/${shareRequest.imageCode}_t`;
    var twitterOpenLink =
      'https://twitter.com/intent/tweet?url=' +
      encodeURIComponent(shareLink) +
      '&text=' +
      encodeURIComponent(this.props.websiteSettings.socialShareTitle) +
      encodeURIComponent('\n') +
      encodeURIComponent(this.props.websiteSettings.socialShareMessage) +
      '&hashtags=' +
      encodeURIComponent(this.props.websiteSettings.socialShareHashtags);
    window.open(twitterOpenLink, '_blank');
  }
  socialShareWhatsApp(shareRequest) {
    var whattsappLink = document.getElementById(
      `hiddenwalink_${shareRequest.imageCode}`,
    );
    whattsappLink.click();
  }

  // SALES FUNCTIONS
  salesChangeCurrency = () => {
    this.setState({ changingCurrency: true });
  };
  salesChangeCurrencyCancelled = () => {
    this.setState({ changingCurrency: false });
  };
  salesChangeCurrencyChanged = (currency) => {
    this.setState({ changingCurrency: false, currency });
  };
  salesAddToBasket = (imageToAdd) => {
    var basket = this.state.basket;
    basket.images.push(imageToAdd);
    imageToAdd.addedToBasket = true;
    const cookies = new Cookies();
    cookies.set('vmp-basket-' + this.state.basketGalleryCode, basket, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    this.setState({ basket });
  };
  salesRemoveFromBasket = (imageToRemove) => {
    var basket = this.state.basket;
    var index = -1;
    var count = 0;
    basket.images.forEach((basketImage) => {
      if (basketImage.imageCode === imageToRemove.imageCode) {
        index = count;
      }
      count++;
    });
    if (index > -1) {
      basket.images.splice(index, 1);
    }
    imageToRemove.addedToBasket = false;
    const cookies = new Cookies();
    cookies.set('vmp-basket-' + this.state.basketGalleryCode, basket, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    this.setState({ basket });
  };
  goToCheckout = (checkoutAll) => {
    if (!checkoutAll && this.state.basket.images.length === 0) return;

    const imageSetPrice = this.state.currency.imageSetPrice;

    let saleGrandTotal =
      this.state.basket.images.length * this.state.currency.singleImagePrice;
    if (!checkoutAll && saleGrandTotal > imageSetPrice) {
      checkoutAll = true;
      toast.info('Basket Discount Applied');
    }

    this.setState({
      checkoutId: null,
      checkoutAll,
      checkOutStarted: true,
    });

    if (checkoutAll) saleGrandTotal = this.state.currency.imageSetPrice;

    var checkoutData = {
      galleryCode: this.state.basketGalleryCode,
      allImages: checkoutAll,
      currencyId: this.state.currency.currencyId,
      currencyCode: this.state.currency.code,
      saleTotal: saleGrandTotal,
      images: this.state.basket.images,
    };

    SnaptAPI.salesCheckout(
      checkoutData,
      this.goToCheckoutSuccess,
      this.goToCheckoutFailed,
    );
  };
  goToCheckoutSuccess = (checkoutResponse) => {
    this.setState({
      checkoutId: checkoutResponse.checkoutId,
      checkingOut: true,
      checkOutStarted: false,
      clientId: checkoutResponse.gateway.clientId,
      clientSecret: checkoutResponse.gateway.clientSecret,
    });
  };
  goToCheckoutFailed = (error) => {
    this.setState({ checkOutStarted: false });
    alert('Checkout failure: ' + error);
  };

  paymentSuccessful = () => {
    var basket = this.state.basket;
    basket.images = [];
    const cookies = new Cookies();
    cookies.set('vmp-basket-' + this.state.basketGalleryCode, basket, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    this.setState({ basket });
  };
  paymentFailure = (message) => {
    this.setState({
      checkingOut: false,
      checkOutStarted: false,
      showErrorBanner: true,
      errorBannerMessage: message,
    });
  };
  closeErrorBanner = () => {
    this.setState({
      showErrorBanner: false,
      errorBannerMessage: '',
    });
  };
  checkoutClose = (refreshGallery) => {
    this.setState({
      checkingOut: false,
      checkOutStarted: false,
    });
    if (refreshGallery) {
      window.location.reload();
    } else {
    }
  };

  closeSafariMessage(e) {
    this.setState({
      safariMessage: false,
    });

    if (e) {
      const cookies = new Cookies();
      cookies.set('vmp_safariMessage', 'hide');
    }
  }

  render() {
    const containerStyle = {
      backgroundColor: this.props.websiteSettings.backgroundColor,
      paddingLeft: this.props.websiteSettings.pageGutter,
      paddingRight: this.props.websiteSettings.pageGutter,
    };

    const photoStyle = {
      paddingBottom: this.props.websiteSettings.photoFrameBottomPadding,
    };

    return (
      <div className="gallery-renderer" style={containerStyle}>
        <style
          dangerouslySetInnerHTML={{
            __html:
              `
                  .app-container { background-color: ` +
              this.props.websiteSettings.backgroundColor +
              `}`,
          }}
        />
        {/*Page header for if there are sales*/}
        <SalesHeader
          downloadableImages={this.props?.imageList?.filter(
            (image) => image.imageURL !== '',
          )}
          currency={this.state.currency}
          allowChangeCurrency={this.props?.availableCurrencies?.length > 1}
          basket={this.state.basket}
          allowAllSales={this.state.allowAllSales}
          allowSales={this.state.allowSales}
          onCheckout={this.goToCheckout}
          onChangeCurrency={this.salesChangeCurrency}
          saleSettings={this.props.saleSettings}></SalesHeader>
        <div className="gr-page">
          {/*Website header defined when creating a template*/}
          <div className="gr-header">
            <ImageBlock
              imageBlockSettings={this.props.headerImageBlock}></ImageBlock>
          </div>

          {/*Actual images*/}
          {this.props.imageList.map(function (image, index) {
            return (
              <div
                key={image.imageCode}
                className="gr-photo"
                style={photoStyle}>
                <PhotoBlock
                  websiteSettings={this.props.websiteSettings}
                  image={image}></PhotoBlock>
                {image.imageURL !== '' || this.props.saleSettings === null ? (
                  <SocialBlock
                    websiteSettings={this.props.websiteSettings}
                    galleryCode={this.state.galleryCode}
                    image={image}
                    onShare={(e) => {
                      this.socialShare(e);
                    }}
                    order={index}></SocialBlock>
                ) : (
                  <div>
                    <ImageSaleBlock
                      websiteSettings={this.props.websiteSettings}
                      image={image}
                      currency={this.state.currency}
                      onAddToBasket={(e) => {
                        this.salesAddToBasket(e);
                      }}
                      onRemoveFromBasket={(e) => {
                        this.salesRemoveFromBasket(e);
                      }}
                      saleSettings={this.props.saleSettings}></ImageSaleBlock>
                  </div>
                )}
              </div>
            );
          }, this)}

          {this.state.allowSales &&
          this.props.saleSettings.paymentGateway === 'paygate' ? (
            <PaygateFooter
              saleSettings={this.props.saleSettings}
              galleryCode={this.props.galleryCode}></PaygateFooter>
          ) : (
            ''
          )}

          <div className="gr-footer">
            <ImageBlock
              imageBlockSettings={this.props.footerImageBlock}></ImageBlock>
          </div>
        </div>
        {this.props.dataCollectionSettings !== null ? (
          <DataCollectionModal
            dataCollectionSettings={this.props.dataCollectionSettings}
            onSubmit={(e) => {
              this.dataCollectionSubmit(e);
            }}
            onDecline={(e) => {
              this.dataCollectionDecline(e);
            }}
            onCancel={(e) => {
              this.dataCollectionCancel(e);
            }}
            isOpen={this.state.collectData}
            galleryCode={this.state.collectDataImageCode}></DataCollectionModal>
        ) : (
          ''
        )}
        {this.state.allowSales ? (
          <SalesCheckoutModal
            isOpen={this.state.checkingOut && this.state.checkoutId !== null}
            checkoutId={this.state.checkoutId}
            checkoutAll={this.state.checkoutAll}
            totalImages={
              this.state.checkoutAll
                ? this.props.imageList.length
                : this.state.basket.images.length
            }
            currency={this.state.currency}
            totalCost={
              this.state.checkoutAll
                ? this.state.currency.imageSetPrice
                : this.state.basket.images.length *
                  this.state.currency.singleImagePrice
            }
            closeCheckout={this.checkoutClose}
            onPaymentSuccess={this.paymentSuccessful}
            onPaymentFailure={this.paymentFailure}
            saleSettings={this.props.saleSettings}
            client={this.state.clientId}
            clientSecret={this.state.clientSecret}
            galleryCode={this.props.galleryCode}></SalesCheckoutModal>
        ) : (
          ''
        )}
        {this.state.allowSales ? (
          <SalesChangeCurrencyModal
            isOpen={this.state.changingCurrency}
            availableCurrencies={this.props.availableCurrencies}
            currency={this.state.currency}
            saleSettings={this.props.saleSettings}
            onCancel={this.salesChangeCurrencyCancelled}
            onChange={
              this.salesChangeCurrencyChanged
            }></SalesChangeCurrencyModal>
        ) : (
          ''
        )}
        {this.state.allowSales ? (
          <SalesMessageModal
            isOpen={this.state.checkOutStarted}
            saleSettings={this.props.saleSettings}
            heading="Preparing Order"
            message="Preparing your order for checkout..."></SalesMessageModal>
        ) : (
          ''
        )}
        {this.state.safariMessage ? (
          <SafariMessageModal
            closeModal={(e) => this.closeSafariMessage(e)}
            isOpen={this.state.safariMessage}
            browser={this.state.browserName}
          />
        ) : (
          <></>
        )}
        {this.state.showErrorBanner ? (
          <ErrorBanner
            closeBanner={this.closeErrorBanner}
            message={this.state.errorBannerMessage}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default GalleryRenderer;
