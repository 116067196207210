import React from 'react';
import './ImageBlock.css';

class ImageBlock extends React.Component {
  state = {
    showImage: false,
  };

  imageLoaded = ({ target: img }) => {
    this.setState({ showImage: true });
  };

  render() {
    const blockStyle = {
      paddingTop: this.props.imageBlockSettings.topPadding,
      paddingBottom: this.props.imageBlockSettings.bottomPadding,
      textAlign:
        this.props.imageBlockSettings.alignment === 0
          ? 'left'
          : this.props.imageBlockSettings.alignment === 2
          ? 'right'
          : 'center',
    };

    const imageStyle = {
      width: this.props.imageBlockSettings.fullWidth ? '100%' : 'auto',
      maxWidth: '100%',
    };

    return (
      <div
        id={'image-block-container-' + this.props.imageBlockSettings.orderIndex}
        className="image-block"
        style={blockStyle}>
        <a
          href={this.props.imageBlockSettings.linkUrl}
          target="_blank"
          rel="noreferrer noopener">
          <img
            src={this.props.imageBlockSettings.imageUrl}
            style={{
              ...imageStyle,
              display: this.state.showImage ? 'block' : 'none',
            }}
            alt="footer logo"
            onLoad={this.imageLoaded}></img>
        </a>
      </div>
    );
  }
}

export default ImageBlock;
