import React from 'react';
import {Link} from "react-router-dom";

class PaymentCancel extends React.Component {
  render() {
    const galleryCode = localStorage.getItem('GALLERY_CODE');

    return (<>
      <h3>
        There was an issue with the payment...
      </h3>

      <p>
        The payment was either cancelled or something went wrong. <br/>
        Please try again, if the problem persists please contact support.
      </p>

      <Link to={galleryCode ? `/${galleryCode}` : '/'} style={{color: 'blue'}}>
        Go back
      </Link>
    </>)
  }
}

export default PaymentCancel;
