import React from 'react';
import Button from '../button/Button';
import './DownloadAllButtonStyles.css';
import { downloadImage } from '../../utils/ImageUtils';

const DownloadAllButton = ({ downloadableImages }) => {
  const handleDownloadAllImages = () => {
    downloadableImages.forEach(({ imageURL }) => {
      downloadImage(imageURL);
    });
  };

  return (
    <Button
      className="vmp-gallery--download-all-button"
      onClick={handleDownloadAllImages}>
      Download All Photos
    </Button>
  );
};

export default DownloadAllButton;
