import React from 'react';
import stripeImage from '../../images/stripe.png';
import './StripeButton.css';
import StripeService from '../../services/stripeService';

class StripeButton extends React.Component {
  onPayClick = async () => {
    try {
      const result = await StripeService.startPayment(
        this.props.cost,
        this.props.clientSecret,
        this.props.checkoutId,
        this.props.currency,
        this.props.galleryCode,
        this.props.templateId,
      );

      this.props.onSuccess(false);

      localStorage.setItem('GALLERY_CODE', this.props.galleryCode);
      window.open(result.url, '_self');
    } catch (error) {
      if (
        error.data &&
        (error.data ?? { message: 'error' }).message === 'no-currency'
      ) {
        this.props.onError(
          'We could not process your payment, please contact the attraction to check the payment settings. [CURRENCY ERROR]',
        );
      }
    }
  };

  render() {
    return (
      <>
        <div className="stripe-button" onClick={this.onPayClick}>
          <div className="stripe-button-image">
            <img
              src={stripeImage}
              className="stripe-button-image-img"
              alt="stripe button"></img>
          </div>
        </div>
      </>
    );
  }
}

export default StripeButton;
