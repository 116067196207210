import React, { Component } from 'react';
import './SafariMessageModal.css';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    outline: 'none',
  },
};

const Browsers = {
  SAFARI: 'Safari',
  SAFARI_MOBILE: 'Safari Mobile',
};

class SafariMessageModal extends Component {
  state = {
    hideModal: false,
  };

  componentDidMount() {}

  screenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      0
    );
  }
  screenHeight() {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      0
    );
  }

  close = () => {
    this.props.closeModal(this.state.hideModal);
  };

  checkboxChange = (e) => {
    this.setState({
      hideModal: e.target.checked,
    });
  };

  cbxLabelClick = (e) => {
    this.setState({
      hideModal: e,
    });
  };

  render() {
    const containerStyle = {
      maxWidth: Math.min(this.screenWidth() - 40, 400),
      maxHeight: this.screenHeight() - 40,
    };

    return (
      <div>
        <ReactModal
          isOpen={this.props.isOpen}
          contentLabel={this.props.heading}
          style={customStyles}
          className="safari-message-modal"
          overlayClassName="safari-message-modal-overlay">
          <div className="sdm-container" style={containerStyle}>
            <div className="header">
              <div className="title">Safari Download</div>
              <div className="controls"></div>
            </div>

            <div className="message">
              If your picture is not downloading, or you are unable to share the
              image to Facebook or Twitter.
              {this.props.browser === Browsers.SAFARI ? (
                <>
                  <p>
                    Please ensure that your Safari allows popups. <br />
                    If Safari shows a message asking to continue, please click
                    'Allow' to get your download. See below to enable Safari
                    popups for ViewMyPics.
                  </p>
                  <img
                    className="sdm-img"
                    src="/assets/images/safari/blocked_message.png"
                    alt="popup blocked"
                  />
                  <img
                    className="sdm-img"
                    src="/assets/images/safari/blocked_icon.png"
                    alt="popup blocked"
                  />
                  <figcaption className="sdm-img-caption">
                    Click icon next to the refresh button
                  </figcaption>
                </>
              ) : (
                <>
                  <p>
                    To allow these options on your safari browser, on your
                    mobile device please go to: <br /> Settings &gt; Safari &gt;
                    Block pop-up &gt; switch this off
                  </p>
                </>
              )}
            </div>

            <div className="hide-modal">
              <input
                type="checkbox"
                onChange={this.checkboxChange}
                checked={this.state.hideModal}
              />
              <p onClick={() => this.cbxLabelClick(!this.state.hideModal)}>
                Don't show this message again
              </p>
            </div>

            <div className="success-refresh" onClick={this.close}>
              Close
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SafariMessageModal;
