import React from "react";
import './ErrorBanner.css';

class ErrorBanner extends React.Component {

  closeBanner = () => {
    this.props.closeBanner();
  }

  render () {
    return (
      <div className='error-banner'>
        <div className="error-banner-container">
          <div className="error-banner-message">
            {this.props.message}
          </div>
          <div className="error-banner-gotit" onClick={this.closeBanner}>
            Close
          </div>
        </div>
      </div>
    )
  }
}

export default ErrorBanner;
