import React, { Component } from 'react';
import './SalesChangeCurrencyModal.css';
import ReactModal from 'react-modal';



ReactModal.setAppElement('#root');

const customStyles = {
    content : {      
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      transform             : 'translate(-50%, -50%)',
      width	                : 'auto',
      height	              : 'auto',
      outline               : 'none',
    }
  };


class SalesChangeCurrencyModal extends Component {


    componentDidMount(){
      
    }

    screenWidth() {
      return window.innerWidth 
          || document.documentElement.clientWidth 
          || document.body.clientWidth 
          || 0;
    }  
    screenHeight() {
        return window.innerHeight 
            || document.documentElement.clientHeight 
            || document.body.clientHeight 
            || 0;
    }


    changeCancelled = () => {
        this.props.onCancel();
    }
    currencySelected = (currency) => {
      this.props.onChange(currency);
    }



    render() {

      const containerStyle = {
        maxWidth: Math.min(this.screenWidth() - 40,400),
        maxHeight: this.screenHeight() - 40
      };

      const headerStyle = {
        backgroundColor:    this.props.saleSettings.salesControlsColor,
      }


      return(   

        
        <div>  

          <ReactModal 
              isOpen={this.props.isOpen}
              contentLabel="Change Currrency"
              style={customStyles}
              className="sales-change-currency-modal"
              overlayClassName="sales-change-currency-modal-overlay">

              <div className="sccm-container" style={containerStyle}>

                <div className="header" style={headerStyle}>
                  <div className="title">
                      Select Currency
                  </div>
                  <div className="controls">
                    <div className="close-button" 
                        onClick={this.changeCancelled}>
                        Cancel X
                    </div>
                  </div>
                </div>

                <div className="currencies">
                  {this.props.availableCurrencies.map(function (currency) {
                      return (
                        <div key={currency.currencyId} className="currency-container"
                            onClick={() => {this.currencySelected(currency)}}>

                            <div className="currency-image" >
                              <img className="currency-img" src={currency.icon} alt='currency icon'/>
                            </div>  

                            <div className="currency-details" >
                              <div className="currency-code" >
                                {currency.code} ({currency.symbol})
                              </div>                           
                              <div className="currency-name" >
                                {currency.name}
                              </div> 
                            </div>  

                        </div>  
                      );
                    }, this) 
                  }
                </div>

              </div>





          </ReactModal>



        </div>

      );
    }


  }
  
  export default SalesChangeCurrencyModal;