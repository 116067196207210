import axios from 'axios';
import DES from 'crypto-js/tripledes';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import Base64 from 'crypto-js/enc-base64';
import LogRocket from 'logrocket';

const startPayment = (
  amount,
  clientSecret,
  checkoutId,
  currency,
  code,
  templateId,
) => {
  const cryptkey = Utf8.parse(process.env.REACT_APP_SECRET_KEY ?? '');
  const crypted = Base64.parse(clientSecret);
  const iv = Hex.parse('00000000000000000000000000000000');
  const dec = DES.decrypt({ ciphertext: crypted }, cryptkey, {
    iv,
  });

  const decrypted = dec.toString(Utf8);

  const dto = {
    amount,
    Name: 'sales',
    CancelUrl: process.env.REACT_APP_PAYMENT_CANCEL,
    SuccessUrl: `${process.env.REACT_APP_PAYMENT_SUCCESS}?gateway=stripe&checkoutid=${checkoutId}&code=${code}`,
    gatewayName: 'stripe',
    stripe_key: decrypted,
    currency,
    templateId: templateId,
    albumCode: code,
  };

  return axios
    .post(`${process.env.REACT_APP_API_URL}stripe/PublicPayment`, dto, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      LogRocket.captureException(error, {
        tags: ['checkout', 'stripe'],
      });
      throw error.response;
    });
};

const StripeService = {
  startPayment,
};

export default StripeService;
