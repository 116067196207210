import LogRocket from 'logrocket';

export const convertImageUrlToDownloadableUrl = async (imageUrl = '') => {
  const url = 'https://corsproxy.io/?' + encodeURIComponent(imageUrl);
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    })
    .catch((err) => {
      LogRocket.captureException(err);
    });
};

export const downloadImage = async (
  imageUrl = '',
  fileName = 'Happy-Snappy.jpg',
) => {
  if (!imageUrl) {
    return;
  }
  console.log(imageUrl);
  const a = document.createElement('a');
  a.href = await convertImageUrlToDownloadableUrl(imageUrl);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
