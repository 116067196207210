import React from 'react';
import './DataCollectionRenderer.css';


class DataCollectionRenderer extends React.Component {


  state = {
    collectedName:"",
    collectedEmail:"",
    collectedPhone:"",
  };


  onNameChange = (e) =>{
    e.preventDefault();
    this.setState({collectedName:e.target.value});
  }
  onEmailChange = (e) =>{
    e.preventDefault();
    this.setState({collectedEmail:e.target.value});
  }
  onPhoneChange = (e) =>{
    e.preventDefault();
    this.setState({collectedPhone:e.target.value});
  }




  submitClick = (e) => {
    e.preventDefault();
    var allValid = document.getElementById("input-validations").checkValidity();
    if (!allValid)  {
       alert('Invalid data entered. Please ensure all fields are filled in correctly and show a green tick before submitting.');
    } else  {
      var collectedData = { galleryCode: this.props.galleryCode, collectedName: this.state.collectedName, collectedEmail: this.state.collectedEmail, collectedPhone: this.state.collectedPhone};
      this.props.onSubmit(collectedData);
    }
  }
  declineClick = () => {
    this.props.onDecline();
  }
  cancelClick = () => {
    this.props.onCancel();
  }


  render() {


    const headerFooterStyle = {
      backgroundColor: this.props.collectionSettings.headerFooterBackgroundColor,
      color: this.props.collectionSettings.headerFooterTextColor,
    };

    const collectionContainerStyle = {
      backgroundColor: this.props.collectionSettings.bodyBackgroundColor,
      color: this.props.collectionSettings.bodyTextColor,   
    };

    const declineButtonStyle = {
      backgroundColor: this.props.collectionSettings.declineButtonColor,
      color: this.props.collectionSettings.declineButtonTextColor,
    };

    const submitButtonStyle = {
      backgroundColor: this.props.collectionSettings.submitButtonColor,
      color: this.props.collectionSettings.submitButtonTextColor,   
      // width: this.props.collectionSettings.collectionEnforcing > 0 ? "" : "93%"
    };

    const termsLinkStyle = {
      display: "inline",
      cursor: "pointer",
      color: this.props.collectionSettings.termsAcceptLinkColor,   
    };


    return (
        <div className="dcm-renderer" >

          <div className="dcm-header-footer-container dcm-header-container" style={headerFooterStyle}>
            <h2 className='dm-header-h2'>
              {this.props.collectionSettings.headerText}
            </h2>

            <div className="dcm-body-text">
              {this.props.collectionSettings.bodyText}
            </div>
          </div>

          <div className="dcm-body-container" style={collectionContainerStyle}>

            <form className="dcm-body-data-inputs" id="input-validations">

              {this.props.collectionSettings.collectName === true ?
                <div className="dcm-body-data-input">        
                    <input  type="text"
                            required="true"
                            pattern=".{3,}"
                            data-value-missing = "required"
                            className="dcm-renderer-form-control"
                            placeholder="Name"
                            id="dcm-name-input"
                            value={this.state.collectedName}
                            onChange={(e) => {this.onNameChange(e)}} />        
                </div>
                :
                ""
              }
              
              {this.props.collectionSettings.collectEmail === true ?
                <div className="dcm-body-data-input">        
                     <input type="text" 
                            required="true"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                            data-value-missing = "required"
                            className="dcm-renderer-form-control"
                            placeholder="Email" 
                            id="dcm-email-input" 
                            value={this.state.collectedEmail}
                            onChange={(e) => {this.onEmailChange(e)}} />  
                </div>
                :
                ""
              }
              
              {this.props.collectionSettings.collectPhone === true ?
                <div className="dcm-body-data-input">        
                     <input type="tel" 
                            required="true"
                            pattern="^[0-9-+() ]*$"
                            minLength="10"
                            data-value-missing = "required"
                            className="dcm-renderer-form-control"
                            placeholder="Phone" 
                            id="dcm-phone-input" 
                            value={this.state.collectedPhone}
                            onChange={(e) => {this.onPhoneChange(e)}} />  
                </div>
                :
                ""
              }

            </form>


            <div className="dcm-body-buttons">
                <div className="dcm-body-button" 
                     style={submitButtonStyle}
                     onClick={(e) => (this.submitClick(e))}>        
                  {this.props.collectionSettings.submitButtonText}
                </div>
              {this.props.collectionSettings.collectionEnforcing === 0?
                <div className="dcm-body-button"
                     style={declineButtonStyle}
                     onClick={this.declineClick}>
                  {this.props.collectionSettings.declineButtonText}
                </div>
                :
                ""
              }
            </div>
          </div>

          <div className="dcm-header-footer-container dcm-footer-container" style={headerFooterStyle}>
            {this.props.collectionSettings.termsAcceptMode === 0 ?
              <div>
                <input className="dcm-inline-element dcm-terms-checkbox" type = "checkbox" id = "chkHam" value = "0" />                 
                <div className="dcm-inline-element">
                  I have read and agree to the <div style={termsLinkStyle} onClick={this.props.onShowTerms}> terms and conditions / privacy policy. </div> 
                </div>
              </div>
              :
              <div>
                By clicking Submit you agree to the <div style={termsLinkStyle}  onClick={this.props.onShowTerms}> terms and conditions / privacy policy.</div>
              </div>
            }
          </div>

        </div>
    );
  }
 

}

export default DataCollectionRenderer;