import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }

  render() {
    const paypal = window.PAYPAL;

    const { total, env, commit, client, onSuccess, onError, onCancel } =
      this.props;

    const { showButton } = this.state;

    const payment = () =>
      paypal.rest.payment.create(env, client, {
        transactions: [
          {
            amount: {
              total,
              currency: 'USD',
            },
          },
        ],
      });

    const onAuthorize = (data, actions) => {
      //var orderId = "";
      // actions.order.get().then(function (orderDetails) {
      //   let orderId = orderDetails.id;
      actions.payment.execute().then((paymentDetails) => {
        const payment = {
          paid: true,
          cancelled: false,
          cartId: paymentDetails.cart,
          orderID: data.orderID,
          payerID: data.payerID,
          paymentID: data.paymentID,
          paymentToken: data.paymentToken,
          returnUrl: data.returnUrl,
        };
        onSuccess(payment);
      });

      // });
    };

    const buttonStyle = {
      layout: 'vertical', // horizontal | vertical
      size: 'responsive', // medium | large | responsive
      shape: 'rect', // pill | rect
      color: 'blue', // gold | blue | silver | white | black
    };

    return (
      <div>
        {showButton && (
          <paypal.Button.react
            env={env}
            client={client}
            commit={commit}
            payment={payment}
            onAuthorize={onAuthorize}
            onCancel={onCancel}
            onError={onError}
            style={buttonStyle}
          />
        )}
      </div>
    );
  }
}

export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(
  PaypalButton,
);
