import axios from "axios";
import MD5 from "crypto-js/md5";
import DES from "crypto-js/tripledes"
import Utf8 from "crypto-js/enc-utf8"
import Hex from "crypto-js/enc-hex"
import Base64 from "crypto-js/enc-base64"

export const PAYGATE_URLS = {
  Initiate: 'https://secure.paygate.co.za/payweb3/initiate.trans',
  Redirect: 'https://secure.paygate.co.za/payweb3/process.trans',
  Query: 'https://secure.paygate.co.za/payweb3/query.trans' 
}

const startPayment = (amount, clientId, clientSecret, currencyCode, checkoutId, code) => {
  const cryptkey = Utf8.parse(process.env.REACT_APP_SECRET_KEY ?? '');
  const crypted = Base64.parse(clientSecret);
  const iv = Hex.parse("00000000000000000000000000000000");
  const dec = DES.decrypt({ciphertext: crypted}, cryptkey , {
    iv
  });

  const decrypted = dec.toString(Utf8);
  const sanitisedQRCode = code.replaceAll("_s", '').trim();

  const dto = new FormData();
  dto.append('PAYGATE_ID', clientId);
  dto.append('REFERENCE', `${sanitisedQRCode}-paygatePayment-${clientId}`);
  dto.append('AMOUNT', (amount * 100).toString());
  dto.append('CURRENCY', currencyCode);
  dto.append('RETURN_URL', `${process.env.REACT_APP_API_URL}sales/paygate/complete?checkoutid=${checkoutId}&code=${code}`);
  dto.append('TRANSACTION_DATE', '2018-01-01 12:00:00');
  dto.append('LOCALE', 'en-za');
  dto.append('COUNTRY', 'ZAF');
  dto.append('EMAIL', '');
  dto.append(
    'NOTIFY_URL',
    `${process.env.REACT_APP_API_URL}paygate/customer-webhook/${checkoutId}`,
  );

  const valueString = Array.from(dto.values()).join('');
  const checksum = MD5(`${valueString}${decrypted}`).toString();

  dto.append('CHECKSUM', checksum);

  return axios.post(PAYGATE_URLS.Initiate, dto, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((response) => {
    return response.data;
  });
}

const queryPayment = (clientId, clientSecret, reference, paymentId, code) => {
  const cryptkey = Utf8.parse(process.env.REACT_APP_SECRET_KEY ?? '');
  const crypted = Base64.parse(clientSecret);
  const iv = Hex.parse("00000000000000000000000000000000");
  const dec = DES.decrypt({ciphertext: crypted}, cryptkey , {
    iv
  });

  const decrypted = dec.toString(Utf8);
  const sanitisedQRCode = code.replaceAll('_s', '').trim();

  const dto = new FormData();
  dto.append('PAYGATE_ID', clientId);
  dto.append('PAY_REQUEST_ID', paymentId);
  dto.append('REFERENCE', `${sanitisedQRCode}-paygatePayment-${clientId}`);

  const valueString = Array.from(dto.values()).join('');
  const checksum = MD5(`${valueString}${decrypted}`).toString();
  dto.append('CHECKSUM', checksum);

  return axios.post(PAYGATE_URLS.Query, dto, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((response) => {
    return response.data;
  });
}

const PaygateService = {
  startPayment,
  queryPayment
}

export default PaygateService;
