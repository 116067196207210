import React, { Component } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Home from '../pages/home';
import Gallery from '../pages/gallery';
import Cookies from 'universal-cookie';
import ReactModal from 'react-modal';
import ModalInfoDisplayRenderer from '../renderers/modal-info-display-renderer/ModalInfoDisplayRenderer';
import PaygateRedirect from '../services/paygateRedirect';
import PaymentCancel from '../services/paymentCancel';
import LogRocketLogger from '../components/loggers/LogRocketLogger';
import Toast from '../components/notifications/Toast';

class App extends Component {
  state = {
    showCookiePolicy: false,
  };

  cookiePolicyShow = () => {
    this.setState({ showCookiePolicy: true });
  };
  cookiePolicyClose = () => {
    this.setState({ showCookiePolicy: false });
  };

  cookiesAgree = () => {
    const cookies = new Cookies();
    cookies.set('vmp-cookies-agreed', true, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    document.getElementById('cookie-notice').style.display = 'none';
  };

  render() {
    const cookies = new Cookies();
    const modalStyle = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        outline: 'none',
      },
    };

    return (
      <div className="app-container">
        <Toast />
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route path="/paygate/redirect" render={() => <PaygateRedirect />} />
          <Route path="/payment/cancel" render={() => <PaymentCancel />} />
          <Route exact path="/:code" render={() => <Gallery />} />
          <Route exact path="//:code" render={() => <Gallery />} />
          {/* <Route exact path='//:code' render={() => <Gallery />} /> */}
          <Route path="/:code/:imageCode" render={() => <Gallery />} />
          <Route path="//:code/:imageCode" render={() => <Gallery />} />
        </Switch>

        {cookies.get('vmp-cookies-agreed') ? (
          ''
        ) : (
          <div id="cookie-notice" className="uses-cookies-notification">
            <div className="uses-cookies-container">
              <div className="uses-cookies-message">
                We use cookies to ensure we give you the best experience on our
                website. By using this website you agree to our{' '}
                <div
                  className="cookie-policy-link"
                  onClick={this.cookiePolicyShow}>
                  {' '}
                  cookie policy.{' '}
                </div>
              </div>
              <div
                className="uses-cookies-gotit"
                onClick={() => {
                  this.cookiesAgree();
                }}>
                Got It
              </div>
            </div>
          </div>
        )}
        <LogRocketLogger />
        <ReactModal
          isOpen={this.state.showCookiePolicy}
          contentLabel="Cookie Policy"
          style={modalStyle}
          className="cookie-policy-modal"
          overlayClassName="cookie-policy-modal-overlay">
          <div className="">
            <ModalInfoDisplayRenderer
              collectionId={0}
              galleryCode={'na'}
              infoType={'cookie-policy'}
              onClose={this.cookiePolicyClose}></ModalInfoDisplayRenderer>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default App;
