import React from 'react';
import './PaygateFooter.css';
import paygateRequiredImg from '../../images/paygate-required.png';
import ReactModal from 'react-modal';
import ModalInfoDisplayRenderer from '../../renderers/modal-info-display-renderer/ModalInfoDisplayRenderer';
import paygateRequiredpdf from '../../images/termsfeed-privacy-policy-pdf-english.pdf';

ReactModal.setAppElement('#root');

const customStyles = {
    content : {      
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      transform             : 'translate(-50%, -50%)',
      width	                : 'auto',
      height	              : 'auto',
      outline               : 'none',
    }
  };


class PaygateFooter extends React.Component {

  state = {      
    showTerms: false
  }

  componentDidMount() {

  }

  termsClick = () => {
    this.setState({showTerms:true});
  }
  termsClose = () => {
    this.setState({showTerms:false});
  }


  render() {
    return (
      <div className={this.props.hideLogos ? "paygate-footer paygate-footer-no-logos" : "paygate-footer "}>
          <div className="pf-container">

            {!this.props.hideLogos ? 
              <div className="pf-logos">    
                <div className="pf-logos-heading">
                  <span>Payments by </span>
                  
                </div>
                <div className="pf-logos-image">
                  <img className="pf-logos-image-img" alt='footer logo'
                      src={paygateRequiredImg}> 
                  </img>
                </div>             
              </div>
              :
              ""
            }   


            <div className="pf-terms">
            <a className="pf-terms-link" target="_blank" rel='noreferrer noopener'
                href={paygateRequiredpdf}>
                Terms and Conditions
              </a>
            </div>
          </div>


          <ReactModal 
              isOpen={this.state.showTerms}
              contentLabel="Terms And Conditions"
              style={customStyles}
              className="data-collection-modal"
              overlayClassName="data-collection-modal-overlay">
            <div className="">
                <ModalInfoDisplayRenderer 
                  collectionId={this.props.saleSettings.templateId}
                  galleryCode={this.props.galleryCode}
                  infoType={"template-terms-privacy"}
                  onClose={this.termsClose} >
                </ModalInfoDisplayRenderer>
            </div>
          </ReactModal>    


      </div>
    );
  }


  
}

export default PaygateFooter;