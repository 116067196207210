import React from 'react';
import './ImageSaleBlock.css';

class ImageSaleBlock extends React.Component {


  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }


  addToBasket = () => {
    this.props.onAddToBasket(this.props.image);
  }
  removeFromBasket = () => {
    this.props.onRemoveFromBasket(this.props.image);
  }









  render() {
    var rgbValues = this.hexToRgb(this.props.websiteSettings.photoFrameColor);
    var backgroundColor = "rgb(" + rgbValues.r  + "," + rgbValues.g  + "," +  rgbValues.b  + "," + this.props.websiteSettings.photoFrameOpacity + ")";
    const imageSaleBlockStyle = {
      paddingLeft:          this.props.websiteSettings.photoFrameSidePadding,
      paddingRight:         this.props.websiteSettings.photoFrameSidePadding,
    };
    const imageSaleContainerStyle = {
      backgroundColor:          backgroundColor,
      padding:                  this.props.websiteSettings.photoFrameWidth,
      paddingTop:               0,
      borderBottomRightRadius:  this.props.websiteSettings.photoFrameRoundedBorders ? "10px" : "unset",
      borderBottomLeftRadius:   this.props.websiteSettings.photoFrameRoundedBorders ? "10px" : "unset",
    };
    const addToBasketControlStyle = {
      backgroundColor: this.props.saleSettings.salesControlsColor,
    }
    const removeFromBasketControlStyle = {
      color: this.props.saleSettings.salesControlsColor,
    }

    return (
        <div className="image-sale" style={imageSaleBlockStyle}>
          <div className="is-container" style={imageSaleContainerStyle}>

              {this.props.image.addedToBasket === undefined || !this.props.image.addedToBasket ?
                <div className="add-to-basket-container">
                  <div className="add-to-basket-control"
                      onClick={this.addToBasket}
                      style={addToBasketControlStyle}>
                    Add to Basket:  {this.props.currency != null ?
                                    <div className="add-to-basket-price">
                                      {this.props.currency.symbol}{this.props.currency.singleImagePrice.toFixed(2)}  
                                    </div> 
                                    :
                                    ""
                                  }
                  </div> 
                </div> 
              :
                <div className="remove-from-basket-container">
                  <div className="remove-from-basket-control"
                       onClick={this.removeFromBasket}
                       style={removeFromBasketControlStyle}>
                     <div className="added-text">
                        ADDED TO BASKET    
                     </div>    
                     <div className="remove-text">
                        [REMOVE]    
                     </div>            
                  </div>
                </div> 
              }

          </div>
        </div>
    );
  }


    

}

export default ImageSaleBlock;