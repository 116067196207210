import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './Home.css';
import SnaptAPI from '../../services/snaptAPI';
import paygateRequiredImg from '../../images/paygate-required.png';
import paygateRequiredpdf from '../../images/termsfeed-privacy-policy-pdf-english.pdf';
class Home extends Component {
  state = {
    status: 'idle',
    searchCode: '',
    gallerySearch: '',
    codeError: '',
    goHome: false,
  };

  componentDidMount() {
    ReactGA.initialize('UA-127917789-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  getQueryStringValue(key) {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          '^(?:.*[&\\?]' +
            encodeURIComponent(key).replace(/[.+*]/g, '\\$&') +
            '(?:\\=([^&]*))?)?.*$',
          'i',
        ),
        '$1',
      ),
    );
  }

  searchCodeChange = (e) => {
    e.preventDefault();
    this.setState({ searchCode: e.target.value, codeError: '' });
  };
  searchCodeKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      this.goSearch(e);
    }
  };

  goSearch = (e) => {
    e.preventDefault();
    // Validate The Search Code Prefix
    if (
      !(
        this.state.searchCode.startsWith('@') ||
        this.state.searchCode.startsWith('$') ||
        this.state.searchCode.startsWith('!')
      )
    ) {
      this.setState({ codeError: 'BadCode' });
      return;
    }
    // Validate Search Code Length
    if (this.state.searchCode.length < 5) {
      this.setState({ codeError: 'BadCode' });
      return;
    }
    this.validateCode(this.state.searchCode);
  };

  clearNoImages = (e) => {
    this.setState({ searchCode: '', codeError: '' });
  };

  validateCode() {
    this.setState({ status: 'validating' });
    SnaptAPI.galleryCodeValidate(
      this.state.searchCode,
      this.validateCodeSuccess,
      this.validateCodeFailed,
    );
  }
  validateCodeSuccess = () => {
    this.setState({
      codeError: '',
      gallerySearch: this.state.searchCode,
      status: 'idle',
    });
  };
  validateCodeFailed = (error) => {
    if (error === 'BadCode') {
      this.setState({ codeError: 'BadCode' });
    }
    if (error === 'NoImages') {
      this.setState({ codeError: 'NoImages' });
    }
  };

  galleryRedirect = () => {
    if (this.state.gallerySearch !== '') {
      // return <Redirect to={"/" + this.state.gallerySearch + "_s"} />
      window.open(`/${this.state.gallerySearch}_s`, '_self');
    }
  };

  setSymbol = () => {
    if (this.state.searchCode === '') {
      this.setState({
        searchCode: '@',
      });
    }
  };

  render() {
    return (
      <div className="new-home-container">
        {this.galleryRedirect()}

        <div className="new-home-container-inner">
          <div className="new-home-content-container no-images-error">
            <div className="new-home-title-container">View My Pics</div>

            <div className="new-home-logo-container">
              powered by{' '}
              <a
                href="https://www.snaptmedia.com"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link">
                {' '}
                <img
                  className="new-home-logo"
                  src={'/assets/images/hsl_logo.png'}
                  alt="logo"
                />{' '}
              </a>
            </div>

            {this.state.codeError !== 'NoImages' ? (
              <div className="new-home-leadin-container">
                Enter your unique code to find your photos!
              </div>
            ) : (
              <div></div>
            )}

            {this.state.codeError !== 'NoImages' ? (
              <div
                className="new-home-search-input-container"
                disabled={
                  this.state.status === 'validating' ? 'disabled' : 'enabled'
                }>
                <input
                  className="new-home-search-input"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Code Here"
                  value={this.state.searchCode}
                  onClick={this.setSymbol}
                  onChange={(e) => {
                    this.searchCodeChange(e);
                  }}
                  onKeyPress={(e) => {
                    this.searchCodeKeyPress(e);
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}

            {this.state.codeError !== '' ? (
              this.state.codeError === 'BadCode' ? (
                <div className="new-home-search-error-container">
                  <div className="new-home-search-error">Invalid Code</div>
                </div>
              ) : this.state.codeError === 'NoImages' ? (
                <div className="new-home-no-images-container">
                  <div className="new-home-no-images-code">
                    {this.state.searchCode}
                  </div>
                  <div className="new-home-no-images-message">
                    Your images have not yet been uploaded to our server. This
                    could be due to signal problems or the photographer is still
                    in process of uploading them. Please check back again a bit
                    later.
                  </div>
                  <div
                    className="new-home-no-images-button"
                    onClick={(e) => {
                      this.clearNoImages(e);
                    }}>
                    OK
                  </div>
                </div>
              ) : (
                <div className="new-home-search-error-container"></div>
              )
            ) : (
              <div className="new-home-search-error-container"></div>
            )}

            {this.state.codeError !== 'NoImages' ? (
              <div
                className="new-home-search-button-container"
                disabled={
                  this.state.status === 'validating' ? 'disabled' : 'enabled'
                }>
                <div
                  className="new-home-search-button"
                  onClick={(e) => {
                    this.goSearch(e);
                  }}>
                  GO
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className=" new-home-content-container imgfooter">
            <div className="pf-logos-heading">
              <span>Payments by </span>
            </div>
            <img
              className="pf-logos-image-img"
              alt="logo"
              src={paygateRequiredImg}></img>
            <div className="pf-terms">
              <a
                className="pf-terms-link"
                target="_blank"
                rel="noreferrer noopener"
                href={paygateRequiredpdf}>
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
