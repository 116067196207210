import React, { useState } from 'react';
import './SalesCheckoutModal.css';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import PaypalButton from '../../components/paypal-button/PaypalButton';
import PaygateButton from '../../components/paygate-button/PaygateButton';
import SnaptAPI from '../../services/snaptAPI';
import PaygateFooter from '../../components/paygate-footer/PaygateFooter';
import StripeButton from '../../components/stripe-button/StripeButton';

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%,-50%)',
    width: 'auto',
    height: 'auto',
    outline: 'none',
  },
};

const CLIENT = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
  production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
  key: '',
};

const ENV =
  process.env.REACT_APP_NODE_ENV === 'production' ? 'production' : 'sandbox';

const SalesCheckoutModal = ({
  saleSettings,
  isOpen,
  closeCheckout,
  onPaymentSuccess,
  onPaymentFailure,
  checkoutId,
  client,
  clientSecret,
  checkoutAll,
  totalImages,
  currency,
  totalCost,
  galleryCode,
}) => {
  const [status, setStatus] = useState('pendingPayment');
  const [usingPayPal, setUsingPayPal] = useState(
    saleSettings.usePaymentProviderPayPal
  );
  const [usingPaygate, setUsingPaygate] = useState(
    saleSettings.usePaymentProviderPaygate &&
      !saleSettings.usePaymentProviderPayPal
  );
  const allowPayPal = saleSettings.usePaymentProviderPayPal;
  const allowPaygate = saleSettings.usePaymentProviderPaygate;
  const paymentGateway = saleSettings.paymentGateway;

  const templateId = saleSettings ? saleSettings.templateId : null;
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    0;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    0;

  const checkoutCancelled = () => {
    const abortSaleData = {
      checkoutId: checkoutId,
      abortReason: 0,
      message: 'User cancelled the checkout',
    };
    SnaptAPI.salesAbort(abortSaleData, null, null);
    setStatus('pendingPayment');
    closeCheckout(false);
  };

  const onChangePaymentGateway = (event) => {
    if (event.target.value === 'PayPal') {
      setUsingPayPal(true);
      setUsingPaygate(false);
    } else {
      setUsingPayPal(false);
      setUsingPaygate(true);
    }
  };

  // PayPal Callbacks
  const paymentCancelledPayPal = (data) => {
    const abortSaleData = {
      checkoutId: checkoutId,
      abortReason: 1,
      message: JSON.stringify(data),
    };
    SnaptAPI.salesAbort(abortSaleData, null, null);
    setStatus('pendingPayment');
    closeCheckout(false);
  };

  const paymentErrorPayPal = (error) => {
    const abortSaleData = {
      checkoutId: checkoutId,
      abortReason: 2,
      message: JSON.stringify(error),
    };
    SnaptAPI.salesAbort(abortSaleData, null, null);
    setStatus('paymentError');
  };

  const paymentSuccessPayPal = (payment) => {
    const verifySaleData = {
      paymentProvider: 'PayPal',
      checkoutId: checkoutId,
      cartId: payment.cartId,
      orderId: payment.orderID,
      payerId: payment.payerID,
      paymentId: payment.paymentID,
      paymentToken: payment.paymentToken,
      paid: payment.paid,
      cancelled: payment.cancelled,
    };
    SnaptAPI.salesVerify(
      verifySaleData,
      paymentVerificationSuccess,
      paymentVerificationFailed
    );
    setStatus('paymentVerification');
  };

  const paymentVerificationSuccess = () => {
    setStatus('paymentSuccess');
    onPaymentSuccess();
  };

  const paymentVerificationFailed = (error) => {
    setStatus('paymentVerificationFailed');
  };

  const paymentSuccessClose = (e) => {
    setStatus('pendingPayment');
    closeCheckout(e);
  };

  const paymentFailureClose = (e) => {
    setStatus('pendingPayment');
    onPaymentFailure(e);
  };

  const paymentErrorClose = () => {
    setStatus('pendingPayment');
    closeCheckout(false);
  };

  const containerStyle = {
    maxWidth: Math.min(screenWidth - 40, 400),
    maxHeight: screenHeight - 40,
    minHeight:
      status === undefined || status === 'pendingPayment' ? '340px' : 'unset',
  };

  CLIENT.sandbox = (client ?? '').trim() !== '' ? client : CLIENT.sandbox;
  CLIENT.key = clientSecret;

  const headerStyle = {
    backgroundColor: saleSettings.salesControlsColor,
  };

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        contentLabel="Checkout"
        style={customStyles}
        className="sales-checkout-modal"
        overlayClassName="sales-checkout-modal-overlay">
        <div className="scm-container" style={containerStyle}>
          <div className="header" style={headerStyle}>
            <div className="title">
              {status === 'pendingPayment'
                ? 'Order Summary'
                : status === 'paymentVerification'
                ? 'Checkout In Process'
                : status === 'paymentVerificationFailed'
                ? 'Payment Verification Failed'
                : status === 'paymentError'
                ? 'Payment Error'
                : status === 'paymentSuccess'
                ? 'Payment Completed'
                : 'Checkout'}
            </div>
            <div className="controls">
              {status === 'pendingPayment' ? (
                <div className="close-button" onClick={checkoutCancelled}>
                  Cancel
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {status === 'pendingPayment' ? (
            <div className="payment-controls">
              <div className="checkout-summary">
                <div className="checkout-details">
                  {checkoutAll ? (
                    <div className="photo-info">
                      All Photos ({totalImages} images)
                    </div>
                  ) : (
                    <div className="photo-info">
                      {totalImages} photo{totalImages > 1 ? 's' : ''} @
                      {currency.symbol}
                      {currency.singleImagePrice.toFixed(2)} each.
                    </div>
                  )}
                </div>
                <div className="checkout-total">
                  <div className="total-text">TOTAL ({currency.code}):</div>
                  <div className="total-price">
                    {currency.symbol}
                    {totalCost.toFixed(2)}
                  </div>
                </div>
              </div>
              {allowPayPal && allowPaygate ? (
                <div>
                  <div className="payment-notes">
                    Select a payment provider below to make payment.
                  </div>
                  <div
                    className="payment-gateway-selector"
                    onChange={onChangePaymentGateway}>
                    <div className="pgs-option">
                      <input
                        id="payPalRadio"
                        type="radio"
                        value="PayPal"
                        name="payment-gateway"
                        checked={usingPayPal}
                      />
                      PayPal
                    </div>
                    <div className="pgs-option">
                      <input
                        type="radio"
                        value="PayGate"
                        name="payment-gateway"
                        checked={usingPaygate}
                      />
                      Paygate
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {paymentGateway === 'paypal' ? (
                <div className="payment-gateway">
                  <div className="payment-notes">
                    Click a card icon below to make a direct card payment or use
                    the PayPal button to use an existing PayPal account.
                  </div>
                  <PaypalButton
                    client={CLIENT}
                    env={ENV}
                    commit={true}
                    currency={currency.code}
                    total={totalCost}
                    onSuccess={paymentSuccessPayPal}
                    onError={paymentErrorPayPal}
                    onCancel={paymentCancelledPayPal}
                  />
                </div>
              ) : (
                ''
              )}
              {paymentGateway === 'paygate' ? (
                <div className="payment-gateway">
                  <div className="payment-notes">
                    Pay with <b>Paygate</b> using the button below.
                  </div>
                  <PaygateButton
                    clientId={client}
                    clientSecret={clientSecret}
                    checkoutId={checkoutId}
                    galleryCode={galleryCode}
                    onSuccess={paymentSuccessClose}
                    currency={currency.code}
                    cost={totalCost}
                  />
                </div>
              ) : (
                ''
              )}
              {paymentGateway === 'stripe' ? (
                <div className="payment-gateway">
                  <div>
                    Pay with <b>Stripe</b> using the button below.
                  </div>
                  <StripeButton
                    templateId={templateId}
                    galleryCode={galleryCode}
                    clientSecret={clientSecret}
                    checkoutId={checkoutId}
                    onSuccess={paymentSuccessClose}
                    onError={paymentFailureClose}
                    currency={currency.code}
                    cost={totalCost}
                  />
                </div>
              ) : (
                ''
              )}
              <div className="checkout-notes">
                NOTE: Once your payment is completed, your image(s) will be
                available for download from the gallery.
              </div>
              {usingPaygate ? (
                <PaygateFooter
                  saleSettings={saleSettings}
                  galleryCode={galleryCode}
                  hideLogos={true}
                />
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {status === 'paymentStarted' ? (
            <div className="payment-verification">
              Making payment through payment gateway...
            </div>
          ) : (
            ''
          )}
          {status === 'paymentVerification' ? (
            <div className="payment-verification">Verifying payment...</div>
          ) : (
            ''
          )}
          {status === 'paymentVerificationFailed' ? (
            <div className="payment-verification-failed">
              <div className="error-message">
                Payment verification with the payment provider has failed.
                <br />
                <br />
                If you have any queries, please contact us at
                photosales@happysnappy.co.za quoting your checkout reference
                below.
                <br />
                <br />
                Checkout Reference
                <br />
                <span className="error-checkout-ref">{checkoutId}</span>
              </div>
              <div className="verification-close" onClick={paymentErrorClose}>
                Close
              </div>
            </div>
          ) : (
            ''
          )}
          {status === 'paymentError' ? (
            <div className="payment-error">
              <div className="error-message">
                The payment provider reported an error with the payment.
                <br />
                <br />
                If you have any queries, please contact us at
                photosales@happysnappy.co.za quoting your checkout reference
                below.
                <br />
                <br />
                Checkout Reference
                <br />
                <span className="error-checkout-ref">{checkoutId}</span>
              </div>
              <div className="error-close" onClick={paymentErrorClose}>
                Close
              </div>
            </div>
          ) : (
            ''
          )}
          {status === 'paymentSuccess' ? (
            <div className="payment-success">
              <div className="success-message">
                Thank you for your payment!
                <br />
                <br />
                An email regarding your purchase has been sent to you. Please
                refresh the gallery to get access to your images.
                <br />
                <br />
                Payment Reference
                <br />
                <span className="error-checkout-ref">{checkoutId}</span>
              </div>
              <div className="success-refresh" onClick={paymentSuccessClose}>
                Refresh Gallery
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default SalesCheckoutModal;

/*
interface SalesCheckoutModalProps {
  saleSettings: {
    usePaymentProviderPayPal: boolean,
    usePaymentProviderPaygate: boolean,
    paymentGateway: string,
    templateId: string | null,
  };
  isOpen: boolean;
  closeCheckout: (arg0: boolean) => void;
  onPaymentSuccess: () => void;
  onPaymentFailure: (arg0: boolean) => void;
  checkoutId: string;
  client: string;
  clientSecret: string;
  checkoutAll: boolean;
  totalImages: number;
  currency: {
    symbol: string,
    singleImagePrice: number,
    code: string,
  };
  totalCost: number;
  galleryCode: string;
}
*/

SalesCheckoutModal.propTypes = {
  saleSettings: PropTypes.shape({
    usePaymentProviderPayPal: PropTypes.bool,
    usePaymentProviderPaygate: PropTypes.bool,
    paymentGateway: PropTypes.string,
    templateId: PropTypes.number,
  }),
  isOpen: PropTypes.bool,
  closeCheckout: PropTypes.func,
  onPaymentSuccess: PropTypes.func,
  onPaymentFailure: PropTypes.func,
  checkoutId: PropTypes.string,
  client: PropTypes.string,
  clientSecret: PropTypes.string,
  checkoutAll: PropTypes.bool,
  totalImages: PropTypes.number,
  currency: PropTypes.shape({
    symbol: PropTypes.string,
    singleImagePrice: PropTypes.number,
    code: PropTypes.string,
  }),
  totalCost: PropTypes.number,
  galleryCode: PropTypes.string,
};
