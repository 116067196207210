import React from 'react';
import './ModalInfoDisplayRenderer.css';
import SnaptAPI from '../../services/snaptAPI';
import ReactHtmlParser from 'react-html-parser';
import { cookiePolicy } from './cookie-policy';


class ModalInfoDisplayRenderer extends React.Component {

  state = {
    infoData: null,
    fecthError: ""
  };


  componentDidMount() {
    this.getWebsiteInfo();
  }

  getWebsiteInfo = () => {  

    switch (this.props.infoType) {
      default:
        SnaptAPI.getWebsiteInfo(this.props.collectionId,this.props.galleryCode,this.props.infoType,this.getWebsiteInfoSuccess,this.getWebsiteInfoFailed);
        break;

      case 'cookie-policy':
        this.getWebsiteInfoSuccess({
          title: 'Website Usage Information',
          htmlInfo: cookiePolicy
        })
        break;
    }

  }
  getWebsiteInfoSuccess = (websiteInfoData) => {
    this.setState({infoData:websiteInfoData});
  }
  getWebsiteInfoFailed = (message) => {
    this.setState({infoData:1,fecthError:message});
  }


  screenWidth() {
    return window.innerWidth 
        || document.documentElement.clientWidth 
        || document.body.clientWidth 
        || 0;
  }  
  screenHeight() {
      return window.innerHeight 
          || document.documentElement.clientHeight 
          || document.body.clientHeight 
          || 0;
  }


  render() {

    const baseModalStyle = {
       maxWidth: this.screenWidth() - 40,
       maxHeight: this.screenHeight() - 40
    };


    return (
        this.state.infoData != null ?
          <div className="mid-renderer" style={baseModalStyle}>
            <div className="header">
              <div className="title">
                {this.state.fecthError === "" ?
                  this.state.infoData.title
                  :
                  "Error"
                }
              </div>
              <div className="controls">
                <div className="close-button" 
                    onClick={this.props.onClose}>
                    Close X
                </div>
              </div>
            </div>
            <div className="info-content">
              {this.state.fecthError === "" ?
                ReactHtmlParser(this.state.infoData.htmlInfo)
                :
                this.state.fecthError
              }
            </div>
          </div>
          :
          <div className="info-loading">
              <div>
                ... loading ...
              </div>
          </div>
    );
  }
 

}

export default ModalInfoDisplayRenderer;