import React, { Component } from 'react';
import '../../pages/home/Home.css';
import paygateRequiredImg from '../../images/paygate-required.png';
import paygateRequiredpdf from '../../images/termsfeed-privacy-policy-pdf-english.pdf';

class NoImageError extends Component {
  state = {
    code: ''
  }
  componentDidMount() {
    this.setState({
      code: this.props.code
    })
  }
  
  goHome() {
    window.location.href = '/';
  }

  render() {
    return (<>
      <div className='new-home-container'>
        <div className='new-home-container-inner'>
          <div className="new-home-content-container no-images-error">

            <div className="new-home-title-container">
              View My Pics
            </div>

            <div className="new-home-logo-container">
              powered by <a href="https://www.snaptmedia.com" target="_blank" rel='noopener noreferrer' className="logo-link"> <img className="new-home-logo" src={'/assets/images/hsl_logo.png'} alt="logo" /> </a>
            </div>

            <div className="new-home-no-images-container">
              <div className="new-home-no-images-code">
                {this.state.code}
              </div>
              <div className="new-home-no-images-message">
                Your images have not yet been uploaded to our server. This could be due to signal problems or the photographer is still in process of uploading them. Please check back again a bit later.
              </div>
              <div className="new-home-no-images-button" onClick={ () => this.goHome() }>
                OK
              </div>
            </div>
          </div>
          <div className=" new-home-content-container imgfooter">
            <div className="pf-logos-heading">
              <span>Payments by </span>

            </div>
            <img className="pf-logos-image-img" alt='logo'
              src={paygateRequiredImg}>
            </img>
            <div className="pf-terms">
              <a className="pf-terms-link" target="_blank" rel='noreferrer noopener'
                href={paygateRequiredpdf}>
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </>)
  }
}

export default NoImageError;